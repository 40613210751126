
.navbar {
  background-color: $cms-contrast-background-color;
  height: 100%;
  width: 240px;
  position: fixed;
  padding: $spacing * 4;
  top: 0;

  &__scroll {
    overflow-y: scroll;
    height: 100%;
  }

  &__logo {
    text-align: center;
    img {
      width: 50px;
    }
  }

  &__title {
    text-align: center;
  }

  &__items {
    margin-top: $spacing * 2;
  }

  &__item {
    display: flex;
    justify-content: space-around;
    padding: $spacing * 3 $spacing * 4;
    margin-bottom: $spacing * 2;
    border-radius: $spacing;
    text-decoration: none;

    &:hover {
      background-color: $color-off-white;
    }

    &-active {
      background-color: $color-white;
    }

    &__text {
      flex-grow: 1;
      margin-left: $spacing * 2;

    }
  }
}
