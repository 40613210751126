$background-color: #FFFFFF;
$green-color: #3a5f1b;
$warning-color: #fdda83;
$success-color: #96b382;
$error-color: #db4021;
$cms-contrast-background-color: #F3F6F2;

//default colors
$color-white: #ffffff;
$color-off-white: #fdfdfd;
$color-grey--dark: #404040;
$color-grey--medium: #c2c2c2;
$color-grey--light: #dbdbdb;
$color-black: #000000;
$color-text: #212529;

//extra effect colors
$shadow-color: $color-grey--medium;
