.newplaygroundform {
  display: flex;
  justify-content: space-between;

  &__form {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    input, textarea {
      margin-bottom: $spacing * 4;
      width: 100%;
    }
  }
  &__map {
    min-width: 300px;
    width: calc(100% - 100px);
    margin-left: 100px;
  }

  .button {
    margin-top: 2 * $spacing;
  }
}
