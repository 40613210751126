.phases {


  &__table {
    display: table;
  }

  &__item {
    display: table-row;

  }

  &__title {
    font-weight: bold;
    div {
      margin-left: $spacing * 2;
      display: inline;
      font-weight: normal;
      text-decoration: underline;
    }
    img {
      width: 15px
    }
  }

  &__key {
    display: table-cell;
    padding: $spacing * 2 $spacing * 2 $spacing * 2 0;
    border-bottom: 1px solid $cms-contrast-background-color;

  }

  &__value {
    display: table-cell;
    padding: $spacing * 2 $spacing * 2 $spacing * 2 0;
    border-bottom: 1px solid $cms-contrast-background-color;
    vertical-align: middle;
  }

  .button {
    margin-top: $spacing * 4;
  }

}
