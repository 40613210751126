.imageUpload {
  width:200px;
  img {
    width:200px;
    border: 1px solid $color-grey--light;
    cursor: pointer;
    position: absolute;
  }

  input {
    display: none;
  }

  span {
    width:200px;
    text-align: center;
    position: absolute;
    margin-top: 100px;
  }

  .message {
    margin-top: 160px;
    position: absolute;
    width: 170px;
  }
}
