.login {
  width: 300px;
  margin: 80px auto;
  background-color: $cms-contrast-background-color;
  padding: 8 * $spacing;

  border-radius: 10px;

  &__logo {
    text-align: center;
    img {
      width: 80px;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 4 * $spacing;
  }

  &__form {
    input {
      margin-bottom: 4 * $spacing;
    }
  }

  &__rememberMe {
    margin-bottom: $spacing * 4;
  }

  .button {
    width: 100%;
  }
}
