.submenu {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-grey--light;
  padding-bottom: $spacing * 3;
  margin-bottom: $spacing * 3;

  &__item {
    cursor: pointer;
    &-active {
      border-bottom: 2px solid $green-color;
    }
  }
}
