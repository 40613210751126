.message {
  padding: 3 * $spacing 4 * $spacing;
  margin: 4 * $spacing auto;
  &--warning {
    background-color: $warning-color;
  }

  &--success {
    background-color: $success-color;
  }
}
