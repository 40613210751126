.previewTableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $spacing * 3;

  button {
    margin-left: $spacing * 3;
  }
}
