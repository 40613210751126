.checkbox {
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }

  &-selected {
    display: none;
  }
  &-empty {
    display: block;
  }

  &__image {
    width: 25px;
    height: 25px;
    margin-right: $spacing;
    cursor: pointer;
  }

  &__label {
    display: flex;
    align-items: center;
  }

  input[type='checkbox']:checked ~ {
    .checkbox-selected {
      display: block;
    }
    .checkbox-empty {
      display: none;
    }
  }
}
