input[type='email'],
input[type='text'],
input[type='password'],
input[type='number'],
input[type='file']{
  width: calc(100% - #{$spacing * 4});
  max-width: 300px;
  height: 20px;
  padding: $spacing * 2;
  border: 1px solid $color-grey--light;
  border-radius: 5px;
  box-shadow: 0 1px 0 0 $shadow-color;

  &.smallInput{
    width:35px;
  }
}

textarea {
  padding: $spacing * 2;
  border: 1px solid $color-grey--light;
  border-radius: 5px;
  box-shadow: 0 1px 0 0 $shadow-color;
  width: 70%;
  height: 150px;
}

select {
  padding: $spacing * 2;
  width: 100%;
  max-width: 300px + $spacing * 4;
}
