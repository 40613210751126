.playgroundslist {

  &__playgrounds {
    margin-top: $spacing * 2;
    display: table;
  }

  &__header {
    display: table-row;
    font-weight: bold;
  }

  &__item {
    display: table-row;
    text-decoration: none;

    &:hover {
      background-color: $cms-contrast-background-color;
    }
  }

  &__value {
    display:table-cell;
    padding: $spacing * 2 $spacing * 20 $spacing * 2 $spacing * 2;

    &:last-child{
      padding-right: $spacing * 2;
    }
  }

  &__map {
    margin-top: $spacing * 4;
  }
}
