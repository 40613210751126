.concepts {
  input {
    margin-bottom: $spacing * 4;
  }

  &__image {
    margin-bottom: $spacing * 4;
    img {
      width: 200px;
    }

    &__delete {
      cursor: pointer;
      color: $green-color;
    }
  }

  .imageUpload {
    min-height: 200px;
  }

  .button-secondary {
    margin-bottom: $spacing * 4;
  }
}
