a {
  color: $color-grey--dark;

  &.action {
    color: $green-color;
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }
}


