body {
  background-color: $background-color;
  color: $color-text;
  font-size: $body-normal;
  line-height: 1.4 * $body-normal;
}

.pageLoader {
  display: flex;
  margin-top: $spacing * 10;
  flex-direction: column;
  align-items: center;
  img {
    animation: pulse 2s infinite;
    width: 50px;
  }

  p{
    font-size: 40px;
    font-family: 'Gunny-rewritten', sans-serif;
  }
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform:scale(1.1);
  }

}
