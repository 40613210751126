.results {
  &__row {
    padding: $spacing * 2 $spacing * 2 $spacing * 2 0;
    display: flex;
    border-bottom: 1px solid $color-grey--light;
  }

  &__count {
    width: 50px;
  }

  &__title {
    width: 200px;

    img{
      width: 15px;
    }
  }

  &__checkbox {
    margin-bottom: $spacing * 4;
  }

  &__answer {
    margin-bottom: $spacing * 4;

    span {
      display: block;
    }
  }
}
