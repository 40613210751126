.table {
  display: table;

  &__row {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    padding: $spacing * 2;
    border-bottom: 1px solid $color-grey--light;
    vertical-align: middle;
  }

  &__header {
    font-weight: bold;
  }

  &__action {
    cursor: pointer;
  }
}
