.questionList {
  margin-top: $spacing * 2;

  &__questions {
    display: table;
    width: 100%;
  }

  &__question {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    padding: $spacing * 2;
    border-bottom: 1px solid $color-grey--light;
    height: 50px;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }
  }

  &__title {
    font-weight: bold;
    display:block;
  }

  &__delete {
    cursor: pointer;
  }

  &__icon {
    margin-left: 2* $spacing;
    position: absolute;
    margin-top: $spacing;
  }

  &__answerOptions {
    display: flex;
    flex-direction: column;
  }

  &__answerOption {
    display: flex;
    flex-direction: row;
    margin-bottom: 2*$spacing;
  }
}
