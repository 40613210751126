
.block {
  margin: 80px 40px 0 320px;
  max-width: 1000px;

  &__right {
    display: flex;
    justify-content: flex-end;
  }
}
