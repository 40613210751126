.moodboardList {

  &__moodboard {
    display:flex;
    align-items: center;
    max-width: 600px;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey--light;
    padding-top: 2*$spacing;
    padding-bottom: 2* $spacing;
  }
  &__image {
    img {
      height: 80px;
      max-width: 100px;
    }
  }

  &__coins {
    display: flex;

    img{
      width: 20px;
    }
  }

  a {
    margin-top: 4*$spacing;
    display: block;
  }
}
